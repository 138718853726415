<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
        :inline="true"
        ref="formData"
        :model="formData"
        class="demo-form-inline"
        style="text-align: left"
      >
<!--        <el-form-item>-->
<!--          <el-select-->
<!--            style="width: 135px"-->
<!--            class="el-select-smid"-->
<!--            v-model="formData.order_type"-->
<!--            placeholder="订单类型"-->
<!--            clearable-->
<!--          >-->
<!--            <el-option-->
<!--              :label="item.name"-->
<!--              :value="item.id"-->
<!--              v-for="(item, index) in order_type_list"-->
<!--              :key="index"-->
<!--            ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-select
            style="width: 135px"
            class="el-select-smid"
            v-model="formData.search_status"
            placeholder="结算状态"
            clearable
          >
            <el-option label="已结算" value="settled"></el-option>
            <el-option label="待结算" value="no_settle"></el-option>
            <el-option label="暂不参与结算" value="pause_settle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="formData.settlement_time_range"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="结算开始日期"
            end-placeholder="结算结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <!--
      <el-form-item>
        <el-select
          class="el-select-smid"
          v-model="formData.no_settle"
          placeholder="是否参与结算"
        >
          <el-option label="是否参与结算" value="-1"></el-option>
          <el-option label="参与" value="0"></el-option>
          <el-option label="不参与" value="1"></el-option>
        </el-select>
      </el-form-item>
	  -->
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              style="width: 150px"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="订单编号" value="1"></el-option>
              <el-option label="会员编号" value="2"></el-option>
              <el-option label="手机号" value="3"></el-option>
              <el-option label="身份证号" value="4"></el-option>
              <!--<el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      stripe
      border
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        align="center"
        header-align="center"
        prop="id"
        label="#ID"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="ordersn"
        label="订单编号"
        width="200"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        prop="member_info"
        label="会员信息"
        width="300"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.member.member_level === '1'"
            v-html="
              '姓名：' +
              scope.row.member.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.member.mobile
            "
          ></span>
          <span
            v-if="scope.row.member.member_level === '3'"
            v-html="
              scope.row.member.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.member.uname +
              '<br />' +
              '手机号码：' +
              scope.row.member.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.member.identity_number
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="order_type_str"
        label="订单类型"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="goods_amount"
        label="金额"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="total_pv"
        label="PV"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="pay_time"
        label="支付时间"
        width="100"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--          align="center"-->
<!--          header-align="center"-->
<!--          prop="review_time"-->
<!--          label="审核时间"-->
<!--          width="100"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
        align="center"
        header-align="center"
        prop="settlement_time"
        label="结算时间"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="no_settle_str"
        width="110"
        label="是否参与结算"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="settle_status_str"
        label="结算状态"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        fixed="right"
        label="操作"
        width="100"
      >
        <template slot-scope="scope">
          <el-button
            @click="editOrder(scope.row)"
            type="primary"
            size="small"
            plain
            v-if="myfun.checkAuthRule('adminOrderEditOrder')"
            >编辑</el-button
          ><br />
          <!--          <el-button @click="editNet(scope.row, 1)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberRecommend') ">改推荐人</el-button><br />-->
          <!--          <el-button @click="editNet(scope.row, 2)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberParent') && scope.row.member_level === '3'">改安置人</el-button><br />-->
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        style="margin-top: 1rem"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>

    <!-- Form -->

    <el-dialog
      title="编辑订单"
      :visible.sync="editOrderdialogFormVisible"
      v-loading="loading"
    >
      <el-form
        class="huiyuanxinxi"
        :model="editOrderformData"
        :rules="checkEditOrderformRules"
        ref="editOrderformData"
      >
        <el-form-item label="订单编号" :label-width="formLabelWidth" prop="">
          <div style="text-align: left">{{ editOrderformData.ordersn }}</div>
        </el-form-item>
        <el-form-item
          label="结算时间"
          :label-width="formLabelWidth"
          prop="settlement_time"
          required
          style="text-align: left"
        >
          <el-date-picker
            v-model="editOrderformData.settlement_time"
            :default-value="new Date()"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="是否结算"
          :label-width="formLabelWidth"
          style="text-align: left"
          prop="no_settle"
        >
          <el-switch
            class="switch custom"
            v-model="editOrderformData.no_settle"
            :active-value="'1'"
            :inactive-value="'0'"
            active-text="不结算"
            inactive-text="结算"
            active-color="#f56c6c"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editOrderdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doEditOrder('editOrderformData')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "OrderList",
  components: {},
  data() {
    return {
      test: true,
      order_type_list: [],
      formData: {
        order_type: "",
        search_status: "",
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        settlement_time_range: "",
        //no_settle: "-1",
      },
      tableData: [],
      loading: false,
      editOrderdialogFormVisible: false,
      editOrderformData: {
        id: "0",
        ordersn: "",
        settlement_time: "",
        settleTimeStr: "",
        settle_status: true,
        no_settle: false,
      },
      checkEditOrderformRules: {
        settlement_time: [{ required: true, message: "结算时间" }],
      },
      formLabelWidth: "120px",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminOrderGetOrderList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    onSearch() {
      this.getList(1);
    },

    editOrder(row) {
      this.editOrderformData.id = row.id;
      this.editOrderformData.ordersn = row.ordersn;
      this.editOrderformData.settlement_time = row.settlement_time;
      this.editOrderformData.settle_status = row.settle_status;
      this.editOrderformData.no_settle = row.no_settle;
      this.editOrderdialogFormVisible = true;
    },
    doEditOrder(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.editOrderformData.settleTimeStr = this.myfun.timestampToDatetime(
            this.myfun.datetimeToTimestamp(
              this.editOrderformData.settlement_time
            )
          );
          this.myfun.request("adminOrderEditOrder.api", this.editOrderformData)
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.editOrderdialogFormVisible = false;
              } else {
            this.loading = false;
          }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    this.getList()
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}

.el-form-item {
  margin-bottom: 0;
}
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
</style>

<template>
  <div class="mainwhite">
    <el-form v-loading="loading"
        :model="formData"
        class="huiyuanxinxi"
        :rules="checkFormDataRules"
        ref="formData"
    >
      <el-form-item
          label="所属国家"
          :label-width="formLabelWidth"
          ref="searchType"
          prop="searchType"
      >
        <el-select
            style="width: 250px"
            v-model="formData.country_id"
            placeholder="请选择国家/地区"
        >
          <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.name + '-' + item.name_en"
              :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.name_en
              }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="会员编号" :label-width="formLabelWidth" >
        <el-input style="width: 250px"
                  placeholder="请填写会员编号"
                  v-model="formData.uname"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="网络类型" :label-width="formLabelWidth">
        <el-select
            style="width: 120px"
            v-model="formData.netType"
            placeholder="请选择"
        >
          <el-option label="安置网" value="1">安置网</el-option>
          <el-option label="推荐网" value="2">推荐网</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属期别" :label-width="formLabelWidth"
          prop="start_settle_mark"
      >
        <el-cascader placeholder="起始期别" style="width: 180px"
                     v-model="formData.start_settle_mark"
                     :options="start_settle_options"
                     clearable
        ></el-cascader>
      </el-form-item>
<!--      <el-form-item label="结束期别" :label-width="formLabelWidth"-->
<!--          prop="end_settle_mark"-->
<!--      >-->
<!--        <el-cascader placeholder="结束期别" style="width: 180px"-->
<!--                     v-model="formData.end_settle_mark"-->
<!--                     :options="start_settle_options"-->
<!--                     clearable-->
<!--        ></el-cascader>-->
<!--      </el-form-item>-->
      <el-form-item v-if="false"
                    label="注册时间"
                    :label-width="formLabelWidth"
                    ref="regTime"
                    prop="regTime"
      >
        <el-date-picker
            v-model="formData.regTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

        </el-date-picker>
      </el-form-item>
      <el-form-item
          label="支付(审核)时间"
          :label-width="formLabelWidth"
          ref="payTime"
          prop="payTime"
      >
        <el-date-picker
            v-model="formData.payTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

        </el-date-picker>
      </el-form-item>
      <el-form-item
                    label="结算时间"
                    :label-width="formLabelWidth"
                    ref="settleTime"
                    prop="settleTime"
      >
        <el-date-picker
            v-model="formData.settleTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

        </el-date-picker>
      </el-form-item>
      <el-form-item
                    label="导出字段"
                    :label-width="formLabelWidth"
                    ref="column"
                    prop="column"
      >
        <el-checkbox :indeterminate="formData.isIndeterminateCol" v-model="formData.checkAllCol" @change="handleCheckAllChangeCol">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="formData.checkedColumnArr" @change="handleCheckedCitiesChangeCol">
          <el-checkbox v-for="city in formData.columnArr" :label="city" :key="city">{{city}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="formData.checkedColumnArr.length > 0"
          label="字段顺序"
          :label-width="formLabelWidth"
          ref="column"
          prop="column"
      >
        <span v-for="city in formData.checkedColumnArr" :label="city" :key="city"> {{city}} </span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm()">重置</el-button>
      <el-button type="primary" @click="onSubmit()"
      >确定导出</el-button
      >
    </div>

  </div>
</template>

<script>
  import { settleData } from "@/util/settleData";
export default {
  name: "TeamOrderList",
  data() {
    return {
      uploadParams:{},
      formData: {},
      uploadLimit: 1,
      uploadLeaderUrl: '/mapi/'+process.env.VUE_APP_API_URL2+'adminMemberMemberLeaderSearch.api',
      tableData: [],
      loading: false,
      checkFormDataRules:{
        // recommend_uname: [{ required: true, message: "请输入推荐人编号" }],
        // parent_uname: [{ required: true, message: "请输入安置人编号" }],
      },
      formLabelWidth: '150px',
      countryList:[],
      start_settle_options: settleData.start,
      end_settle_options: settleData.end,
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.formData.checkedMemberTypeArr = val ? this.formData.memberTypeArr : [];
      this.formData.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.formData.checkAll = checkedCount === this.formData.memberTypeArr.length;
      this.formData.isIndeterminate = checkedCount > 0 && checkedCount < this.formData.memberTypeArr.length;
    },
    handleCheckAllChangeCol(val) {
      this.formData.checkedColumnArr = val ? this.formData.columnArr : [];
      this.formData.isIndeterminateCol = false;
    },
    handleCheckedCitiesChangeCol(value) {
      let checkedCount = value.length;
      this.formData.checkAllCol = checkedCount === this.formData.columnArr.length;
      this.formData.isIndeterminateCol = checkedCount > 0 && checkedCount < this.formData.columnArr.length;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminMemberMemberLeaderSearch.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    onSearch() {
      this.getList(1);
    },
    resetForm(){
      this.formData = {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        country_id: "",
        settleTime: [],
        payTime:[],
        regTime:[],
        isExport: 0,
        searchType: '1',
        timeType: '1',
        checkAll: false,
        memberType1Arr: ['VIP会员','经销商'],
        checkedMemberType1Arr: [],
        memberTypeArr: ['待审会员', '优惠顾客', '超级优惠顾客', '白金卡', '钻石卡', '至尊卡'],
        checkedMemberTypeArr: [],
        isIndeterminate: false,
        checkAllCol: false,
        // columnArr: ['会员编号','会员姓名', '手机号', '证件号', '会员级别', '经销商级别', '奖衔', '银行名称', '银行卡卡号', '开户名'],
        columnArr: ['所属国家','收货国别','订单编号','会员编号','会员姓名','会员级别','总金额','总PV','支付(审核)时间','结算时间',
          // '结算期别',
          // '联系人','联系电话','收货地址'
        ],
        checkedColumnArr: [],
        isIndeterminateCol: false,
        netType: "1",
        uname:"",
        start_settle_mark: "",
        end_settle_mark: "",

      };
      // this.$refs['formData'].resetFields()
    },
    onSubmit(){
      this.loading = true;
      if(this.formData.checkedColumnArr.length <= 0){
        this.loading = false;
        this.$message.warning('请选择要导出的字段');
        return false;
      }


      this.myfun.request("adminOrderGetTeamOrderList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.myfun.myRouterAutoPush('/data/explor-task-list')
          }
        });

      this.loading = false;


    },
    //获取列表副接口
    getListDeputy(){
      this.myfun.request("adminOrderGetTeamOrderListDeputy.api")
        .then((result) => {
          if (result.code === "1") {
            this.countryList = result.data.countryList;
          }
        });
    }
  },
  created() {
    this.resetForm();
    //副接口
    this.getListDeputy();
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },

  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.el-form-item {
  margin-bottom: 0;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
</style>
